<script>
export default {
  name: "FileUploader",

  props: {
    value: {
      required: false,
      default: null,
    },
    label: {
      required: false,
      type: String,
      default: "",
    },
    rules: {
      required: false,
      type: Array,
      default: () => [],
    },
    errorMessages: {
      required: false,
      type: Array,
      default: () => [],
    },
    accept: {
      required: false,
      type: String,
      default: "",
    },
    preview: {
      required: false,
      type: String,
      default: "",
    },
    truncateLength: {
      required: false,
      type: String,
      default: "",
    },
    outlined: {
      required: false,
      type: Boolean,
      default: false,
    },
    hideIcon: {
      required: false,
      type: Boolean,
      default: false,
    },
    hideDetails: {
      required: false,
      type: Boolean,
      default: false,
    },
    dense: {
      required: false,
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    model: null,
    imagePreview: "",
  }),

  watch: {
    preview: {
      immediate: true,
      handler() {
        this.imagePreview = this.preview;
      },
    },
    value: {
      immediate: true,
      handler() {
        if (typeof this.value === "string") {
          this.model = new File([], this.value || "");
        } else {
          this.model = this.value;
        }
      },
    },
  },

  methods: {
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    async changeData() {
      try {
        if (this.model?.type.includes("image")) {
          this.imagePreview = await this.toBase64(this.model);
        } else {
          this.imagePreview = "";
        }

        this.$emit("input", this.model);
      } catch (e) {
        await this.$store.dispatch("alert/showError", e);
      }
    },
  },
};
</script>

<template>
  <v-file-input
    @change="changeData()"
    v-model="model"
    :rules="rules"
    :accept="accept"
    :label="label"
    :error-messages="errorMessages"
    :truncate-length="truncateLength"
    prepend-icon=""
    :dense="dense"
    :hide-details="hideDetails"
    :outlined="outlined"
  >
    <template #prepend-inner>
      <v-icon :small="hideIcon"> mdi-paperclip </v-icon>
    </template>
  </v-file-input>
</template>

<style scoped>
.picker >>> .v-input__control,
.picker >>> .v-input__slot {
  min-height: 32px !important;
}
</style>
