<script>
export default {
  name: "About",

  data: () => ({
    investigations: [
      {
        moduleName: "pre-investigation",
        paragraphs: 4,
      },
      {
        moduleName: "sedation",
        paragraphs: 3,
      },
      {
        moduleName: "documents",
        paragraphs: 3,
      },
      {
        moduleName: "expectations",
        paragraphs: 3,
      },
      {
        moduleName: "post-investigation",
        paragraphs: 2,
      },
      {
        moduleName: "result",
        paragraphs: 1,
      },
    ],
    appointmentModel: [
      {
        margin: true,
      },
      {
        margin: true,
      },
      {
        margin: false,
      },
      {
        margin: false,
      },
      {
        margin: false,
      },
      {
        margin: true,
      },
      {
        margin: true,
      },
    ],
  }),
};
</script>

<template>
  <div>
    <section class="pt-15" id="rmn">
      <v-container class="px-sm-8">
        <v-row>
          <v-col cols="12" class="py-0">
            <div class="mb-5">
              <span class="underlined-span text-h4 text--text font-weight-bold">
                {{ $t("about.rmn.title") }}
              </span>
            </div>
            <p class="ml-8 mb-3" v-for="i in 9" :key="i">
              {{ $t(`about.rmn.text.${i}`) }}
            </p>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <section class="my-5" id="patients-guide">
      <v-container class="px-sm-8">
        <v-row>
          <v-col cols="12" class="py-0">
            <div class="mb-5">
              <span class="underlined-span text-h4 text--text font-weight-bold">
                {{ $t("about.investigation.title") }}
              </span>
            </div>
            <div
              v-for="(item, index) in investigations"
              :key="index"
              :id="item.moduleName"
            >
              <div class="text--text font-weight-bold text-h6 mb-3">
                <v-icon color="innirm" size="24"> mdi-chevron-right </v-icon>
                <span>
                  {{ $t(`about.investigation.${item.moduleName}.title`) }}
                </span>
              </div>
              <div v-for="i in item.paragraphs" :key="i" class="ml-5">
                <div class="mb-2 ml-3">
                  {{ $t(`about.investigation.${item.moduleName}.text.${i}`) }}
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <section class="my-5" id="how-to-make-an-appointment">
      <v-container class="px-sm-8">
        <v-row>
          <v-col cols="12" class="py-0">
            <div class="mb-5">
              <span class="underlined-span text-h4 text--text font-weight-bold">
                {{ $t("about.how-to-appointment.title") }}
              </span>
            </div>
            <div class="mb-3">
              <div>
                <span>
                  {{ $t("about.how-to-appointment.text.1.title") }}
                </span>
              </div>
              <ul>
                <li v-for="i in 2" :key="i">
                  <i18n
                    :path="`about.how-to-appointment.text.1.text.1-${i}`"
                    tag="div"
                  >
                    <template v-slot:button>
                      <v-btn color="innirm" depressed class="white--text" small>
                        <span>
                          {{ $t("global_buttons.appointment-btn") }}
                        </span>
                      </v-btn>
                    </template>
                  </i18n>
                </li>
              </ul>
            </div>
            <div
              v-for="(item, index) in appointmentModel"
              :key="index"
              :class="{ 'mb-3': item.margin }"
            >
              <i18n
                :path="`about.how-to-appointment.text.${index + 2}`"
                tag="div"
              >
                <template v-slot:terms>
                  <a href="/terms">
                    {{ $t("about.how-to-appointment.often-used.terms") }}
                  </a>
                </template>
                <template v-slot:medic-referral>
                  <span class="font-italic">
                    {{
                      $t("about.how-to-appointment.often-used.medic-referral")
                    }}
                  </span>
                </template>
                <template v-slot:office-mail>
                  <a href="https://mail.google.com/mail"> office@innirm.md </a>
                </template>
                <template v-slot:privacy>
                  <a href="/privacy">
                    {{ $t("about.how-to-appointment.often-used.privacy") }}
                  </a>
                </template>
              </i18n>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<style scoped lang="scss">
.underlined-span {
  position: relative;

  &:after {
    position: absolute !important;
    bottom: -4px !important;
    left: 0 !important;
    content: "" !important;
    height: 4px !important;
    width: 100% !important;
    background: var(--v-innirm-base);
  }
}
@media (min-width: 1904px) {
  .container {
    max-width: 1185px !important;
  }
}
</style>
