<script>
export default {
  name: "Footer",
  computed: {
    isLoginPageRoute() {
      let userCabinetPages = ["auth", "profile", "confirm"];
      return userCabinetPages.includes(this.$route.meta.module);
    },
  },
};
</script>

<template>
  <section v-if="isLoginPageRoute === false" class="py-6 innirm-footer-background">
    <v-container class="px-sm-8">
      <v-row class="text--text">
        <v-col cols="12" sm="6" md="3" lg="3" xl="3">
          <span class="text-h5 font-weight-bold">
            {{ $t("footer.title") }}
          </span>
          <p class="mb-3 mt-5 text-body-2">
            {{ $t("footer.text.street") }}
          </p>
          <p class="mb-0 text-body-2">
            <span class="font-weight-bold">
              {{ $t("footer.text.phone") }}
            </span>
            +373 (0) 22 80 80 20
          </p>
          <p class="text-body-2">
            <span class="font-weight-bold">{{ $t("footer.text.email") }}</span>
            office@innirm.md
          </p>
          <div>
            <v-btn
              min-width="36"
              width="36"
              height="36"
              depressed
              link
              to="#"
              class="innirm mr-2 px-0"
            >
              <v-icon color="white" size="18"> mdi-twitter</v-icon>
            </v-btn>
            <v-btn
              min-width="36"
              width="36"
              height="36"
              depressed
              link
              to="#"
              class="innirm mr-2 px-0"
            >
              <v-icon color="white" size="18"> mdi-facebook</v-icon>
            </v-btn>
            <v-btn
              min-width="36"
              width="36"
              height="36"
              depressed
              link
              to="#"
              class="innirm mr-2 px-0"
            >
              <v-icon color="white" size="18"> mdi-instagram</v-icon>
            </v-btn>
            <v-btn
              min-width="36"
              width="36"
              height="36"
              depressed
              link
              to="#"
              class="innirm mr-2 px-0"
            >
              <v-icon color="white" size="18"> mdi-skype</v-icon>
            </v-btn>
            <v-btn
              min-width="36"
              width="36"
              height="36"
              depressed
              link
              to="#"
              class="innirm px-0"
            >
              <v-icon color="white" size="18"> mdi-linkedin</v-icon>
            </v-btn>
          </div>
        </v-col>
        <v-col cols="12" sm="6" md="2" lg="2" xl="2">
          <h3 class="text-h6 font-weight-bold mb-3">
            {{ $t("footer.text.useful.title") }}
          </h3>
          <ul>
            <li class="d-flex align-center mb-3">
              <v-icon color="innirm" size="24"> mdi-chevron-right</v-icon>
              <span @click="$vuetify.goTo('#services')" class="hoverable">
                {{ $t("footer.text.useful.text.1") }}
              </span>
            </li>
            <li class="d-flex align-center mb-3">
              <v-icon color="innirm" size="24"> mdi-chevron-right</v-icon>
              <span @click="$vuetify.goTo('#specialists')" class="hoverable">
                {{ $t("footer.text.useful.text.2") }}
              </span>
            </li>
            <li class="d-flex align-center mb-3">
              <v-icon color="innirm" size="24"> mdi-chevron-right</v-icon>
              <span @click="$vuetify.goTo('#about')" class="hoverable">
                {{ $t("footer.text.useful.text.3") }}
              </span>
            </li>
            <li class="d-flex align-center mb-3">
              <v-icon color="innirm" size="24"> mdi-chevron-right</v-icon>
              <a href="https://inn.md" class="hoverable">
                {{ $t("footer.text.useful.text.4") }}
              </a>
            </li>
            <li class="d-flex align-center mb-3">
              <v-icon color="innirm" size="24"> mdi-chevron-right</v-icon>
              <span @click="$vuetify.goTo('#home')" class="hoverable">
                {{ $t("footer.text.useful.text.5") }}
              </span>
            </li>
          </ul>
        </v-col>
        <v-col cols="12" sm="6" md="2" lg="2" xl="2">
          <h3 class="text-h6 font-weight-bold mb-3">
            {{ $t("footer.text.policy.title") }}
          </h3>
          <ul>
            <li class="d-flex align-center mb-3">
              <v-icon color="innirm" size="24"> mdi-chevron-right</v-icon>
              <a href="/terms" class="hoverable">
                {{ $t("footer.text.policy.text.terms") }}
              </a>
            </li>
            <li class="d-flex align-center mb-3">
              <v-icon color="innirm" size="24"> mdi-chevron-right</v-icon>
              <a href="/privacy" class="hoverable">
                {{ $t("footer.text.policy.text.privacy") }}
              </a>
            </li>
          </ul>
        </v-col>
        <v-col cols="12" sm="6" md="5" lg="5" xl="5" class="pl-10">
          <GmapMap
            :center="{ lat: 10, lng: 10 }"
            :zoom="7"
            map-type-id="terrain"
            style="width: 100%; height: 250px"
          >
          </GmapMap>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<style scoped lang="scss">
ul {
  padding: 0 !important;
}

@media (min-width: 1904px) {
  .container {
    max-width: 1185px !important;
  }
}
</style>
