<script>
export default {
  name: "terms",

  data: () => ({
    list: [
      {
        paragraphs: 2,
      },
      {
        paragraphs: 2,
      },
      {
        paragraphs: 1,
      },
      {
        paragraphs: 2,
      },
      {
        paragraphs: 2,
      },
      {
        paragraphs: 2,
      },
      {
        paragraphs: 1,
      },
      {
        paragraphs: 1,
      },
      {
        paragraphs: 1,
      },
    ],
  }),
};
</script>

<template>
  <div class="mt-12">
    <v-container>
      <v-row>
        <v-col cols="12">
          <div class="text-center mb-5">
            <span class="text-h3 font-weight-bold">
              {{ $t("terms.title") }}
            </span>
          </div>
          <div>
            <div class="mb-3">
              <span class="font-weight-bold text-h5 innirm--text">
                {{ $t("terms.text.1.title") }}
              </span>
            </div>
            <i18n tag="p" class="mb-2 ml-6" path="terms.text.1.text.1">
              <template v-slot:office-mail>
                <a href="https://mail.google.com/mail"> office@innirm.md, </a>
              </template>
            </i18n>
            <ul style="list-style: outside" class="mb-3">
              <li class="ml-6">
                <i18n path="terms.text.1.text.2-1.text" class="mb-0">
                  <template v-slot:click-here>
                    <router-link to="/privacy">
                      {{ $t("terms.often-used.click-here") }}
                    </router-link>
                  </template>
                  <template v-slot:privacy>
                    <span class="innirm--text">
                      {{ $t("terms.text.1.text.2-1.privacy") }}
                    </span>
                  </template>
                </i18n>
              </li>
              <li class="ml-6">
                <i18n path="terms.text.1.text.2-2.text" class="mb-0">
                  <template v-slot:click-here>
                    <router-link to="/about#patients-guide">
                      {{ $t("terms.often-used.click-here") }}
                    </router-link>
                  </template>
                  <template v-slot:guide>
                    <span class="innirm--text">
                      {{ $t("terms.text.1.text.2-2.guide") }}
                    </span>
                  </template>
                </i18n>
              </li>
            </ul>
          </div>
          <div>
            <div class="mb-3">
              <span class="font-weight-bold text-h5 innirm--text">
                {{ $t("terms.text.2.title") }}
              </span>
            </div>
            <ul>
              <li v-for="(item, index) in list" :key="index">
                <div class="list-title">
                  <span class="font-weight-bold text-h6">
                    {{ $t(`terms.text.2.text.${index + 1}.title`) }}
                  </span>
                </div>
                <i18n
                  tag="p"
                  :key="i"
                  class="mb-2 ml-6"
                  v-for="(child, i) in item.paragraphs"
                  :path="`terms.text.2.text.${index + 1}.text.${index + 1}-${
                    i + 1
                  }`"
                >
                  <template v-slot:office-mail>
                    <a href="https://mail.google.com/mail">
                      office@innirm.md,
                    </a>
                  </template>
                </i18n>
              </li>
            </ul>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<style scoped lang="scss">
.underlined {
  position: relative;

  &:after {
    position: absolute;
    bottom: -4px;
    left: 0;
    content: "";
    height: 4px;
    width: 100px;
    background: var(--v-innirm-base);
  }
}

ul {
  list-style: none;
}

@media (min-width: 1904px) {
  .container {
    max-width: 1185px !important;
  }
}
</style>
