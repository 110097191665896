<script>
import axios from "axios";
import store from "@/store";

import FileUploader from "@/components/form-components/FileUploader";
import ImageViewer from "@/components/viewers/Image";

export default {
  name: "Application",

  components: {
    FileUploader,
    ImageViewer,
  },

  props: {
    item: {
      required: true,
      type: Object,
    },
    value: {
      required: false,
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    dialog: false,
    datepicker_modal: false,
    agree_processing: false,
    agree_terms: false,
    saving: false,
    saved: false,

    model: {
      center_id: 1,
    },
    service: {
      name: "",
      price: "",
      duration: "",
    },

    selects: {
      times: [],
    },
    errorMessages: {},
  }),

  async mounted() {
    await this.getData();
    this.setService();
  },

  computed: {
    rules() {
      return {
        required: [(v) => !!v || this.$t("global_validation.required")],
        email: [
          (v) =>
            !v || /.+@.+\..+/.test(v) || this.$t("global_validation.email"),
        ],
      };
    },
    computedRules() {
      return {
        min: this.minLengthRule,
        max: this.maxLengthRule,
      };
    },
    isSavable() {
      return !(this.agree_processing && this.agree_terms);
    },
    minDate() {
      return new Date().toISOString().substr(0, 10);
    },
    maxDate() {
      return new Date(new Date().setDate(new Date().getDate() + 1))
        .toISOString()
        .substr(0, 10);
    },
    computedDateFormatted() {
      return this.formatDate(this.model.date);
    },
  },

  watch: {
    value: {
      immediate: true,
      async handler() {
        this.dialog = this.value;
      },
    },
    date() {
      this.formattedDate = this.formatDate(this.model.date);
    },
  },

  methods: {
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}.${month}.${year}`;
    },
    async getData() {
      try {
        const services = await axios.get(
          `https://inn-api.powerit.dev/api/v1/services/public/1/${store.getters["localization/getCurrent"]}`
        );

        this.selects.services = services.data;
      } catch (e) {
        const response = e.response.data;
        await store.dispatch("alert/showError", response.message);
      }
    },
    async create(payload) {
      await axios.post(
        `https://inn-api.powerit.dev/api/v1/online_requests/public/${store.getters["localization/getCurrent"]}`,
        payload
      );
    },
    async submit() {
      this.saving = true;
      try {
        if (this.$refs.form.validate()) {
          const model = { ...this.model };
          const form = new FormData();

          for (const key in model) {
            if (
              key === "doctor_referral_letter" &&
              typeof model[key] === "string"
            ) {
              continue;
            }
            form.append(key, model[key]);
          }

          await this.create(form);
          this.saved = true;
          await this.$store.dispatch(
            "alert/showSuccess",
            this.$t("global_alert.successful_create")
          );
        }
      } catch (e) {
        const response = e.response.data;
        if (response.errors) {
          this.errorMessages = response.errors;

          setTimeout(() => {
            this.errorMessages = {};
          }, 2000);
          console.log(response);
        }
        await this.$store.dispatch("alert/showError", response.message);
      }
      this.saving = false;
    },
    setPrice() {
      if (!this.model.service_id) {
        this.service.price = "";
        return;
      }
      const chosenService = this.selects.services.find(
        (item) => item.id === this.model.service_id
      );
      this.service.price = chosenService.price;
      this.service.duration = chosenService.duration;
    },
    minLengthRule(number) {
      return (v) =>
        !v ||
        v.length >= number ||
        this.$t("global_validation.min", { number });
    },
    maxLengthRule(number) {
      return (v) =>
        !v ||
        v.length <= number ||
        this.$t("global_validation.max", { number });
    },
    setService() {
      this.service = { ...this.item };
      this.model.service_id = this.item.id;
    },
    closeDialog() {
      this.dialog = false;
      this.$emit("input", this.dialog);
    },
    async openTimesList() {
      const API_SERVER_URL = "https://inn-api.powerit.dev/api/v1";
      let currentTime;
      if (this.isToday(this.model.date)) {
        currentTime = this.$moment().unix();
      } else {
        currentTime = this.$moment(this.model.date).unix();
      }

      const times = await axios.get(
        `${API_SERVER_URL}/online_requests/times_list/${currentTime}`,
        {
          headers: {
            "x-localization": store.getters["localization/getCurrent"],
          },
        }
      );
      this.selects.times = times.data;
    },
    isToday(someDate) {
      const today = new Date().setHours(0, 0, 0, 0);
      return new Date(someDate).setHours(0, 0, 0, 0) === today;
    },
  },
};
</script>

<template>
  <v-dialog v-model="dialog" max-width="1200" @click:outside="closeDialog">
    <v-card v-if="saved">
      <v-card-text class="pa-0">
        <div class="full-height full-width bright-innirm pt-13 pb-15">
          <div class="d-flex justify-center">
            <v-icon color="success" size="256"> mdi-emoticon </v-icon>
          </div>
          <div class="text-center">
            <span class="font-weight-bold text-h5">
              {{ $t("response.alert.title") }}
            </span>
            <p class="text-h6 mt-2">
              {{ $t("response.alert.verify") }}
            </p>
            <v-btn class="innirm white--text" @click="closeDialog" depressed>
              <span>
                {{ $t("global_buttons.close") }}
              </span>
            </v-btn>
          </div>
        </div>
      </v-card-text>
    </v-card>
    <v-card v-else class="pa-5">
      <v-form lazy-validation ref="form">
        <v-card-title>
          <span class="title mb-0">
            {{ $t("home.appointment.form-headers.title") }}
          </span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="6" md="4" lg="4" xl="4" class="py-0">
              <v-text-field
                v-model="model.name"
                :rules="[
                  ...rules.required,
                  computedRules.min(2),
                  computedRules.max(50),
                ]"
                :label="$t('home.appointment.form.name')"
                color="innirm"
              />
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="4" xl="4" class="py-0">
              <v-text-field
                v-model="model.email"
                :rules="[...rules.email, ...rules.required]"
                :error-messages="errorMessages.email"
                :label="$t('home.appointment.form.email')"
                color="innirm"
              />
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="4" xl="4" class="py-0">
              <v-text-field
                v-model="model.phone"
                :rules="[
                  ...rules.required,
                  computedRules.min(8),
                  computedRules.max(12),
                ]"
                :error-messages="errorMessages.phone"
                :label="$t('home.appointment.form.phone')"
              />
            </v-col>
            <v-col cols="12" class="py-0">
              <v-text-field
                v-model="model.comments"
                :rules="[computedRules.min(2), computedRules.max(255)]"
                :error-messages="errorMessages.comments"
                :label="$t('home.appointment.form.comments')"
                color="innirm"
              />
            </v-col>
            <v-col cols="12" class="py-0">
              <p class="title">
                {{ $t("home.appointment.form-headers.details") }}
              </p>
            </v-col>
            <v-col cols="12" class="d-flex justify-space-between py-0">
              <v-row>
                <v-col cols="12" sm="12" md="8" lg="8" xl="8">
                  <v-row>
                    <v-col cols="12" sm="6" class="py-0">
                      <v-menu
                        ref="menu"
                        offset-y
                        min-width="auto"
                        v-model="datepicker_modal"
                        transition="scale-transition"
                        :close-on-content-click="false"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="computedDateFormatted"
                            :rules="[...rules.required]"
                            :error-messages="errorMessages.date"
                            :label="$t('home.appointment.form.date')"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          />
                        </template>
                        <v-date-picker
                          ref="picker"
                          color="innirm"
                          :min="minDate"
                          v-model="model.date"
                          @change="openTimesList"
                          :locale="$store.getters['localization/getCurrent']"
                        />
                      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="6" class="py-0">
                      <v-select
                        v-model="model.time"
                        :rules="[...rules.required]"
                        :error-messages="errorMessages.time"
                        :disabled="!!!model.date"
                        :label="$t('home.appointment.form.time')"
                        :items="selects.times"
                        item-text="text"
                        item-value="value"
                        color="innirm"
                        prepend-icon="mdi-clock-outline"
                      />
                    </v-col>
                    <v-col cols="12" class="mt-2">
                      <v-row>
                        <v-col cols="12" class="py-0">
                          <p class="title mb-0">
                            {{ $t("home.appointment.form-headers.file") }}
                          </p>
                          <file-uploader
                            v-model="model.doctor_referral_letter"
                            :label="$t('home.appointment.form.file')"
                          />
                        </v-col>
                        <v-col cols="12" class="d-flex align-center py-0">
                          <v-checkbox
                            v-model="agree_terms"
                            color="innirm"
                            :true-value="true"
                            :false-value="false"
                            class="mt-0"
                          />
                          <router-link
                            to="/terms"
                            target="_blank"
                            class="mb-4 ml-1"
                          >
                            {{ $t("home.appointment.form.agree_terms") }}
                          </router-link>
                        </v-col>
                        <v-col cols="12" class="d-flex align-center py-0">
                          <v-checkbox
                            v-model="agree_processing"
                            color="innirm"
                            :true-value="true"
                            :false-value="false"
                            class="mt-0"
                          />
                          <router-link
                            to="/privacy"
                            target="_blank"
                            class="mb-4 ml-1"
                          >
                            {{ $t("home.appointment.form.agree_processing") }}
                          </router-link>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                  <v-card max-width="100%">
                    <v-card-text>
                      <span class="font-weight-bold text-body-1 text--text">
                        {{ $t("home.appointment.form-headers.service") }}:
                      </span>
                      <div>
                        <v-select
                          :rules="[...rules.required]"
                          v-model="model.service_id"
                          color="innirm"
                          :placeholder="$t('home.appointment.form.service')"
                          :items="selects.services"
                          item-text="name"
                          item-value="id"
                          @change="setPrice()"
                        />
                      </div>
                      <div class="mt-5">
                        <v-col cols="12" sm="6" class="pa-0">
                          <span class="font-weight-bold text-body-1 text--text">
                            {{ $t("home.appointment.form-headers.duration") }}:
                          </span>
                          <p class="text-body-1 mb-0" v-if="model.service_id">
                            {{ service.duration }}
                            {{ $t("home.appointment.form.minutes") }}
                          </p>
                        </v-col>
                        <v-col cols="6" class="py-0">
                          <image-viewer
                            v-if="service.icon"
                            :src="service.icon"
                            :size="$vuetify.breakpoint.xs ? 300 : 100"
                          />
                        </v-col>
                      </div>
                      <div class="d-flex mt-10 mr-2">
                        <div>
                          <span class="text-h5 font-weight-bold text--text">
                            {{ $t("home.appointment.form-headers.price") }}:
                          </span>
                        </div>
                        <v-spacer />
                        <div>
                          <p class="text--text text-h5 mb-0">
                            <span class="font-weight-bold">
                              {{ model.service_id ? service.price : "---" }}
                            </span>
                            <span>MDL</span>
                          </p>
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="px-5">
          <v-row>
            <v-col class="pa-2 d-flex align-center">
              <span class="caption ml-3">
                {{ $t("global_validation.is_required") }}
              </span>
              <v-spacer></v-spacer>
              <v-btn text @click="closeDialog()">
                {{ $t("global_buttons.cancel") }}
              </v-btn>
              <v-btn
                @click="submit()"
                :disabled="isSavable || saving"
                class="innirm white--text"
                depressed
              >
                {{ $t("global_buttons.confirm") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<style lang="scss" scoped>
.limit-width {
  &::v-deep {
    .v-input__slot {
      max-width: calc(100% - 300px);
    }
  }
}
</style>
