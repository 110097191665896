<script>
export default {
  name: "privacy",

  data: () => ({
    table: [
      {
        reasons: ["services", "obligations", "interest", "protection"],
      },
      {
        reasons: ["interest", "obligations", "services"],
      },
      {
        reasons: ["services", "obligations", "interest"],
      },
      {
        reasons: ["obligations", "interest", "protection"],
      },
      {
        reasons: ["services", "obligations", "interest", "protection"],
      },
      {
        reasons: ["services", "interest"],
      },
      {
        reasons: ["interest", "security"],
      },
    ],
  }),

  methods: {
    test(number) {
      return this.$t(`privacy.test`, { number });
    },
  },
};
</script>

<template>
  <div>
    <section class="pt-15" id="rmn">
      <v-container class="px-sm-8">
        <v-row>
          <v-col cols="12" class="py-0">
            <div class="mb-5">
              <p class="mb-0">
                <span class="text-h4 tennaxt--text font-weight-bold">
                  {{ $t("privacy.title.1") }}
                </span>
              </p>

              <p class="mb-0">
                <span
                  class="underlined-span text-h5 tennaxt--text font-weight-bold"
                >
                  {{ $t("privacy.title.2") }}
                </span>
              </p>
            </div>
            <div class="mb-3">
              <span class="text-h5 font-weight-bold">
                {{ $t("privacy.sub-title.title") }}
              </span>
            </div>
            <div class="mb-3">
              <ol class="mb-5">
                <li v-for="i in 3" :key="i">
                  <i18n :path="`privacy.sub-title.text.${i}.text`">
                    <template v-slot:title>
                      <span class="font-weight-bold">
                        {{ $t(`privacy.sub-title.text.${i}.title`) }}
                      </span>
                    </template>
                  </i18n>
                </li>
              </ol>
              <p class="mb-3 ml-6">
                {{ $t(`privacy.sub-title.text.4`) }}
              </p>
            </div>
            <ol style="list-style: decimal">
              <li class="list-header mb-3">
                <div>
                  <span class="text-h6">
                    {{ $t("privacy.1.title") }}
                  </span>
                </div>
                <ol>
                  <li>
                    <i18n path="privacy.1.text.1.text" tag="p" class="mb-0">
                      <template v-slot:url1>
                        <a
                          href="https://www.legis.md/cautare/getResults?doc_id=110544&lang=ro"
                        >
                          {{ $t("privacy.1.text.1.url1") }}
                        </a>
                      </template>
                      <template v-slot:url2>
                        <a href="https://gdpr.eu/what-is-gdpr/">
                          {{ $t("privacy.1.text.1.url2") }}
                        </a>
                      </template>
                    </i18n>
                  </li>
                  <li v-for="i in 5" :key="i">
                    <span>
                      {{ $t(`privacy.1.text.${i + 1}`) }}
                    </span>
                  </li>
                  <li class="without-marker mt-3">
                    <div class="mb-2">
                      <span class="text--text text-body-1 font-weight-bold">
                        {{ $t("privacy.1.text.7.title") }}
                      </span>
                    </div>
                  </li>
                  <li class="decrement-marker">
                    <div class="mb-3">
                      <span>
                        {{ $t("privacy.1.text.7.text.1") }}
                      </span>
                    </div>
                    <div class="mb-5">
                      <div class="ml-5">
                        <i18n
                          v-for="i in 4"
                          :key="i"
                          :path="`privacy.1.text.7.text.${i + 1}.text`"
                          tag="p"
                          class="mb-0"
                        >
                          <template v-slot:title>
                            <span class="font-weight-bold">
                              {{ $t(`privacy.1.text.7.text.${i + 1}.title`) }}
                            </span>
                          </template>
                          <template v-slot:address>
                            <span>
                              {{ $t(`privacy.1.text.7.text.${i + 1}.address`) }}
                            </span>
                          </template>
                        </i18n>
                      </div>
                    </div>
                    <div>
                      <i18n :path="`privacy.1.text.8.text`" tag="p">
                        <template v-slot:url>
                          <a href="https://www.datepersonale.md">
                            www.datepersonale.md
                          </a>
                        </template>
                      </i18n>
                    </div>
                  </li>
                </ol>
              </li>
              <li class="list-header mb-3">
                <div>
                  <span class="text-h6">
                    {{ $t("privacy.2.title") }}
                  </span>
                </div>
                <ol>
                  <li v-for="i in 2" :key="i">
                    {{ $t(`privacy.2.text.2-${i}`) }}
                  </li>
                </ol>
              </li>
              <li class="list-header mb-3">
                <div>
                  <span class="text-h6">
                    {{ $t("privacy.3.title") }}
                  </span>
                </div>
                <ol>
                  <li>
                    <div class="mb-2">
                      <span>
                        {{ $t(`privacy.3.text.3-1.title`) }}
                      </span>
                    </div>
                    <ol class="mb-3">
                      <li v-for="i in 5" :key="i">
                        {{ $t(`privacy.3.text.3-1.text.3-1-${i}`) }}
                      </li>
                    </ol>
                  </li>
                  <li>
                    <div class="mb-2">
                      <span>
                        {{ $t(`privacy.3.text.3-2.title`) }}
                      </span>
                    </div>
                    <p class="mb-3">
                      {{ $t(`privacy.3.text.3-2.text.1`) }}
                    </p>
                    <ol class="mb-3">
                      <li>
                        {{ $t(`privacy.3.text.3-2.text.3-2-1.title`) }}
                        <ol>
                          <li v-for="i in 4" :key="i">
                            <span>
                              {{
                                $t(
                                  `privacy.3.text.3-2.text.3-2-1.text.3-2-1-${i}`
                                )
                              }}
                            </span>
                          </li>
                        </ol>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <div class="mb-2">
                      <span>
                        {{ $t(`privacy.3.text.3-3.title`) }}
                      </span>
                    </div>
                    <p class="mb-3">
                      {{ $t(`privacy.3.text.3-3.text.1`) }}
                    </p>
                  </li>
                </ol>
              </li>
              <li class="list-header mb-3">
                <div>
                  <span class="text-h6">
                    {{ $t("privacy.4.title") }}
                  </span>
                </div>
                <ol class="mb-3">
                  <li>
                    <span>
                      {{ $t(`privacy.4.text.4-1.title`) }}
                    </span>
                    <ol>
                      <li v-for="i in 3" :key="i">
                        <span>
                          {{ $t(`privacy.4.text.4-1.text.4-1-${i}`) }}
                        </span>
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <li class="list-header mb-3">
                <div>
                  <span class="text-h6">
                    {{ $t("privacy.5.title") }}
                  </span>
                </div>
                <ol class="mb-3">
                  <li v-for="i in 2" :key="i">
                    <span>
                      {{ $t(`privacy.5.text.5-${i}`) }}
                    </span>
                  </li>
                </ol>
                <table class="full-width">
                  <thead>
                    <tr>
                      <th class="text-center">
                        {{ $t(`privacy.5.table.headers.1`) }}
                      </th>
                      <th class="text-center">
                        {{ $t(`privacy.5.table.headers.2`) }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in table" :key="index">
                      <td>
                        <div>
                          <span class="font-weight-bold">
                            {{ $t(`privacy.5.table.items.${index + 1}.title`) }}
                          </span>
                        </div>
                        <p class="mb-0" v-for="(child, i) in 1" :key="i">
                          {{
                            $t(
                              `privacy.5.table.items.${index + 1}.text.${
                                index + 1
                              }-${i + 1}`
                            )
                          }}
                        </p>
                      </td>
                      <td style="width: 50%">
                        <p
                          class="mb-0"
                          v-for="(child, i) in item.reasons"
                          :key="i"
                          style="border-bottom: thin solid rgba(0, 0, 0, 0.08)"
                        >
                          {{ $t(`privacy.5.table.reasons.${child}`) }}
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </li>
              <li class="list-header mb-3">
                <div>
                  <span class="text-h6">
                    {{ $t("privacy.6.title") }}
                  </span>
                </div>
                <ol class="mb-3">
                  <li>
                    <span>
                      {{ $t(`privacy.6.text.6-1.title`) }}
                    </span>
                    <ol>
                      <li v-for="i in 6" :key="i">
                        <span>
                          {{ $t(`privacy.6.text.6-1.text.6-1-${i}`) }}
                        </span>
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <li class="list-header mb-3">
                <div>
                  <span class="text-h6">
                    {{ $t("privacy.7.title") }}
                  </span>
                </div>
                <ol class="mb-3">
                  <li>
                    {{ $t(`privacy.7.text.7-1`) }}
                  </li>
                  <li>
                    <span>
                      {{ $t(`privacy.7.text.7-2.title`) }}
                    </span>
                    <ol>
                      <li v-for="i in 6" :key="i">
                        <i18n
                          :path="`privacy.7.text.7-2.text.7-2-${i}.text`"
                          class="mb-0"
                          tag="p"
                        >
                          <template v-slot:title>
                            <span class="font-weight-bold">
                              {{ $t(`privacy.7.text.7-2.text.7-2-${i}.title`) }}
                            </span>
                          </template>
                        </i18n>
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <li class="list-header mb-3">
                <div>
                  <span class="text-h6">
                    {{ $t("privacy.8.title") }}
                  </span>
                </div>
                <ol class="mb-3">
                  <li>
                    {{ $t("privacy.8.text.8-1") }}
                  </li>
                </ol>
                <i18n path="privacy.8.text.1.text" tag="p" class="mb-3">
                  <template v-slot:url>
                    <a href="https://mail.google.com/mail">dpo@innirm.md</a>
                  </template>
                </i18n>
              </li>
              <li class="list-header mb-3">
                <div>
                  <span class="text-h6">
                    {{ $t("privacy.9.title") }}
                  </span>
                </div>
                <ol class="mb-3">
                  <li v-for="i in 2" :key="i">
                    <span>
                      {{ $t(`privacy.9.text.9-${i}`) }}
                    </span>
                  </li>
                </ol>
              </li>
              <li class="list-header mb-3">
                <div>
                  <span class="text-h6">
                    {{ $t("privacy.10.title") }}
                  </span>
                </div>
                <ol class="mb-3">
                  <li>
                    <i18n path="privacy.10.text.10-1.text" tap="p" class="mb-0">
                      <template v-slot:url>
                        <a href="https://mail.google.com/mail">dpo@innirm.md</a>
                      </template>
                    </i18n>
                  </li>
                  <li>
                    <span>
                      {{ $t(`privacy.10.text.10-2`) }}
                    </span>
                  </li>
                </ol>
              </li>
              <li class="list-header mb-3">
                <div>
                  <span class="text-h6">
                    {{ $t("privacy.11.title") }}
                  </span>
                </div>
                <ol class="mb-3">
                  <li>
                    <div>
                      <span>
                        {{ $t(`privacy.11.text.11-1.title`) }}
                      </span>
                      <ol>
                        <li v-for="i in 6" :key="i">
                          {{ $t(`privacy.11.text.11-1.text.11-1-${i}`) }}
                        </li>
                      </ol>
                    </div>
                  </li>
                  <li>
                    <span>
                      {{ $t(`privacy.11.text.11-2`) }}
                    </span>
                  </li>
                </ol>
              </li>
              <li class="list-header mb-3">
                <div>
                  <span class="text-h6">
                    {{ $t("privacy.12.title") }}
                  </span>
                </div>
                <ol class="mb-3">
                  <li>
                    <span>
                      {{ $t("privacy.12.text.12-1") }}
                    </span>
                  </li>
                </ol>
              </li>
              <li class="list-header mb-3">
                <div>
                  <span class="text-h6">
                    {{ $t("privacy.13.title") }}
                  </span>
                </div>
                <ol class="mb-3">
                  <li>
                    <span>
                      {{ $t("privacy.13.text.13-1") }}
                    </span>
                  </li>
                </ol>
              </li>
              <li class="list-header mb-3">
                <div>
                  <span class="text-h6">
                    {{ $t("privacy.14.title") }}
                  </span>
                </div>
                <p class="ml-10 mb-3">
                  {{ $t("privacy.14.text.1") }}
                </p>
              </li>
            </ol>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<style scoped lang="scss">
.underlined-span {
  position: relative;

  &:after {
    position: absolute !important;
    bottom: -4px !important;
    left: 0 !important;
    content: "" !important;
    height: 4px !important;
    width: 100% !important;
    background: var(--v-innirm-base);
  }
}
li.list-header > div,
li.list-header::before {
  font-weight: bold;
  color: var(--v-innirm-base);
}
.without-marker {
  margin-left: -25px;

  &:before {
    display: none;
  }
}

ol {
  list-style-type: none;
  counter-reset: item;
  margin: 0;
  padding: 0;
}

ol > li {
  display: table;
  counter-increment: item;
  margin-bottom: 0.6em;
}

ol > li:before {
  content: counters(item, ".") ". ";
  display: table-cell;
  padding-right: 0.6em;
}
ol > .decrement-marker {
  counter-set: item 7;
}

li ol > li {
  margin: 0;
}

li ol > li:before {
  content: counters(item, ".") " ";
}

table {
  border-collapse: collapse;

  td,
  th {
    padding: 5px;
    border: thin solid gray;
  }
}

.full-width {
  width: 100%;
}

@media (min-width: 1904px) {
  .container {
    max-width: 1185px !important;
  }
}
</style>
